.back-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 105px;
  height: 40px;
}
.back-button:hover {
  background-color: #A8DEE0 !important; 
  border-color: #A8DEE0 !important; 
}
.select-update-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 80px;
  height: 40px;
}
.select-update-button:disabled {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 10rem !important;
  width: 80px;
  height: 40px;
}
.select-update-button:hover {
  background-color: #A8DEE0 !important; 
  border-color: #A8DEE0 !important; 
}
.upload-button {
  background-color: #5AA7A7 !important;
  border-color: #5AA7A7 !important; 
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 105px
}
.upload-button:disabled {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 10rem !important;
  width: 105px !important;
}
.upload-button:hover {
  background-color: #A8DEE0 !important; 
  border-color: #A8DEE0 !important; 
}
.upload-fab {
  color: #3F7676 !important;
  background-color: #A8DEE0 !important;
}
.download-button {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important; 
  color: #5AA7A7 !important;
  width: 310px
}