.dashboard {
  margin-top: -4%;
  margin-left: 15%;
}
.submit-button {
  background-color: #5AA7A7 !important;
  border-color: #5AA7A7 !important; 
  color: #FFFFFF !important;
  border-radius: 1rem !important;
  width: 220px;
  margin-bottom: 5% !important;
}
.submit-button:disabled {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 1rem !important;
  width: 220px;
  margin-bottom: 5% !important;
}
.update-button {
  background-color: #5AA7A7 !important;
  border-color: #5AA7A7 !important; 
  color: #FFFFFF !important;
  border-radius: 1rem !important;
  width: 120px;
  margin-top: 3% !important;
  margin-bottom: 5% !important;
  margin-right: 1% !important;
}
.update-button:disabled {
  background-color: #D3D3D3 !important;
  border-color: #D3D3D3 !important; 
  border-radius: 1rem !important;
  width: 120px;
  margin-top: 3% !important;
  margin-bottom: 5% !important;
  margin-right: 1% !important;
}
.delete-button {
  background-color: #5AA7A7 !important;
  border-color: #5AA7A7 !important; 
  color: #FFFFFF !important;
  border-radius: 1rem !important;
  width: 120px;
  margin-top: 3% !important;
  margin-bottom: 5% !important;
  margin-right: 1% !important;
}
.delete-button:disabled {
  background-color: #D3D3D3 !important;
  border-color: #D3D3D3 !important; 
  border-radius: 1rem !important;
  width: 120px;
  margin-top: 3% !important;
  margin-bottom: 5% !important;
  margin-right: 1% !important;
}
.insert-button {
  color: #5AA7A7 !important;
  border-color: #5AA7A7 !important; 
}
.close-button {
  color: #5AA7A7 !important;
  font-weight: bold !important;
  align-self: end !important;
}
.action-button {
  color: #5AA7A7 !important;
}
.action-button:disabled {
  color: #D3D3D3 !important;
}
.template-button {
  color: #5AA7A7 !important;
}