.login-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 100px;
  height: 40px;
}
.login-button:disabled {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 10rem !important;
  width: 100px;
  height: 40px;
}
.login-button:hover {
  background-color: #A8DEE0 !important; 
  border-color: #A8DEE0 !important; 
}
.verification-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 300px;
  height: 40px;
}
.verification-button:disabled {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 10rem !important;
  width: 300px;
  height: 40px;
}
.bind-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 300px;
  height: 40px;
}
.bind-button:disabled {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 10rem !important;
  width: 300px;
  height: 40px;
}
.retry-button {
  color: #5AA7A7 !important; 
}
.success-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 300px;
  height: 40px;
}
.loading-spinner {
  color: #5AA7A7 !important;
}